<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the following structures is the correct one for 3-methylbenzoic acid?
      </p>

      <v-radio-group v-model="inputs.input1" class="mb-0" :disabled="!allowEditing">
        <v-radio class="my-2" value="ortho">
          <template #label>
            <v-img :src="imageBName" style="max-width: 133px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="para">
          <template #label>
            <v-img :src="imageCName" style="max-width: 159px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="meta">
          <template #label>
            <v-img :src="imageAName" style="max-width: 162px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="ethyl">
          <template #label>
            <v-img :src="imageDName" style="max-width: 138px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA1Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    imageAName() {
      return '/img/assignments/uci-51lb-a1-q2-option1.png';
    },
    imageBName() {
      return '/img/assignments/uci-51lb-a1-q2-option2.png';
    },
    imageCName() {
      return '/img/assignments/uci-51lb-a1-q2-option3.png';
    },
    imageDName() {
      return '/img/assignments/uci-51lb-a1-q2-option4.png';
    },
  },
};
</script>
